import React from "react";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import fetch from "isomorphic-fetch";

const client = new ApolloClient({
  uri: `${process.env.GATSBY_BACKEND_GRAPHQL_URL}/graphql`,
  fetch,
});

export const wrapRootElement = ({ element }) => {
  return <ApolloProvider client={client}>{element}</ApolloProvider>;
};
